<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Banner -->
<app-background></app-background>
<!-- End Banner -->

<!-- Feature -->
<div class="feature-area feature-area-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-doctor-1"></i>
                    <span>Find a doctor</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature active">
                    <img src="assets/img/icon/appointment.png" alt="Image">
                    <span>Request an appointment</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-first-aid-kit"></i>
                    <span>Emergency case</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Feature -->

<!-- Choose Us -->
<section class="choose-us-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content-two">
                    <span class="top-title">Why choose us</span>
                    <h2>A hospital is an institution where patients are treated. Our doctors are always engaged in this work</h2>
                    <p>We will transfer your prescriptions to the specified department and set up your service Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <ul>
                        <li><span>1</span> We coordinate</li>
                        <li><span>2</span> We schedule</li>
                        <li><span>3</span> We package</li>
                    </ul>
                    <a routerLink="/about" class="default-btn">About us</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img-two">
                    <img src="assets/img/choose-us-img-2.jpg" alt="Image">

                    <div class="address-and-week">
                        <p>For 24/7 emergency please call us. If is no emergency, contact at normal time.</p>
                        <div class="address-week">
                            <ul class="address">
                                <li>
                                    <i class="flaticon-telephone-1"></i>
                                    <span>Call:</span>
                                    <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                                </li>
                                <li>
                                    <i class="flaticon-arroba"></i>
                                    <span>Email:</span>
                                    <a href="mailto:support@vuci.com">support@vuci.com</a>
                                </li>
                                <li>
                                    <i class="flaticon-address-1"></i>
                                    <span>Address:</span>
                                    6890 Blvd, The Bronx, NY 1058 New York, USA
                                </li>
                            </ul>
                            <ul class="week">
                                <li>
                                    Weekdays:
                                    <span>8:00 am – 8:00 pm</span>
                                </li>
                                <li>
                                    Saturday:
                                    <span>9:00 am – 5:00 pm</span>
                                </li>
                                <li>
                                    Sunday:
                                    <span>9:00 am – 3:00 pm</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Choose Us -->

<!-- Appointment -->
<section class="appointment-area pb-100">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-6">
                        <h3>Book an appointment</h3>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <select>
                                <option value="">Doctor Name</option>
                                <option value="">Dr.Juhon Dew</option>
                                <option value="">Dr.Jon Smith Care</option>
                                <option value="">Dr.Ana</option>
                                <option value="">Dr.Kilva</option>
                                <option value="">Dr.Zilka Smith</option>
                                <option value="">Dr.Jilva Dew</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <div class="input-group date" id="datetimepicker">
                                <input type="text" class="form-control" placeholder="07/09/2020">
                                <span class="input-group-addon"></span>
                            </div>	
                            <i class="bx bx-calendar"></i>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6">
                        <button type="submit" class="default-btn">Book Now</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Services -->
<section class="services-area services-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <span>Our departments</span>
            <h2>All the services that we provide to my patients in the field of treatment</h2>
        </div>
        
        <div class="services-slider owl-theme owl-carousel">
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/department-details"><img src="assets/img/services/services-1.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/department-details">Cardiology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/department-details"><img src="assets/img/services/services-2.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/department-details">Neurology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/department-details"><img src="assets/img/services/services-3.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/department-details">Cancer care</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/department-details"><img src="assets/img/services/services-4.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/department-details">Laboratory</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
            
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/department-details"><img src="assets/img/services/services-5.jpg" alt="Image"></a>
                </div>

                <div class="services-content">
                    <h3><a routerLink="/department-details">Dental</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->

<!-- Doctors -->
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our specialists</span>
            <h2>We have specialist doctor’s for emergency services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cosmetic Surgeon</span>
                        <h3>Bernarda N. Bacon</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Surgery Surgeon</span>
                        <h3>Brian Christensen</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Dental surgeon</span>
                        <h3>Aimee C. Mayfield</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cardiothoracic Surgeon</span>
                        <h3>Gabriel Henderson</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctors -->

<!-- Counter -->
<section class="counter-area pb-100">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-satisfy"></i>
                        <h2><span class="odometer" data-count="1688">00</span></h2>
                        <h3>Satisfied patients</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-doctor"></i>
                        <h2>
                            <span class="odometer" data-count="100">00</span> 
                            <span class="target">+</span>
                        </h2>
                        <h3>Medical specialist</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-hospital-bed"></i>
                        <h2><span class="odometer" data-count="347">00</span> </h2>
                        <h3>Medical beds</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-experience"></i>
                        <h2>
                            <span class="odometer" data-count="25">00</span> 
                            <span class="target">+</span>
                        </h2>
                        <h3>Years Of experience</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing plants</span>
            <h2>We have different types pricing plants to meet patient needs and budget</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Starter</h3>
                    <h2><Sub>$</Sub>79</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing active">
                    <h3>Basic</h3>
                    <h2><Sub>$</Sub>99</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <h3>Premium</h3>
                    <h2><Sub>$</Sub>129</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Price -->

<!-- Partner -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="partner-slider-two partner-bg partner-bg-two owl-theme owl-carousel">
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-1.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-2.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-4.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-5.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->

<!-- Testimonial -->
<section class="testimonial-area testimonial-area-two pb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                <h3>Markus Twain</h3>
                <img src="assets/img/testimonial/signature.png" alt="Image">
            </div>
            
            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                <h3>Anna Deo</h3>
                <img src="assets/img/testimonial/signature.png" alt="Image">
            </div>
    
            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                <h3>Kilvis Smith</h3>
                <img src="assets/img/testimonial/signature.png" alt="Image">
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Recent news</span>
            <h2>All the recent news about our treatment has been published</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>07</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">It might be time to seek a medical your muscle and joint pain</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Curtis Warren</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">The reassurance of a safe pregnancy through the birth of your baby</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">James Lopez</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Subscribe -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="assets/img/subscribe-img.png" alt="Image">
                        <h3>Sing up for newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter email address" name="email">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>	
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->