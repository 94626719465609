<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Our Blog</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start log Column One Area -->
<div class="blog-column-one-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-column-one">
                    <div class="row">
                        
                        <div class="col-lg-6 col-md-6" *ngFor="let bgItem of blogService.Posts">
                            <div class="single-blog-post">
                                <div class="blog-img">
                                    <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
                                        <img itemprop="image" *ngIf="bgItem.imageModel" src="{{bgItem.imageModel.small}}" alt="{{bgItem.title}}">
                                        <img itemprop="image" *ngIf="!bgItem.imageModel" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/blog/blog-4.jpg" alt="{{bgItem.title}}">
                                    </a>
    
                                    <div class="date">
                                        <ul>
                                            <li itemprop="validFrom">{{bgItem.createdOn | dateFormat}}</li>
                                        </ul>
                                    </div>
                                </div>
    
                                <div class="blog-content">
                                    <span itemprop="category">{{bgItem.category}}</span>
    
                                    <h3>
                                        <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
                                           {{bgItem.title}}
                                        </a>
                                    </h3>

    
                                    <ul>
                                        <li>
                                            By:
                                            <a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Admin</a>
                                        </li>
                                        <li class="read">
                                            <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
                                                Read More
                                                <i class="flaticon-right-arrow"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li *ngFor="let catItem of categoryService.productCategories">
                                <a routerLink="/department/{{catItem.name.replaceAll(' ','-')}}/{{catItem._id}}" title="{{catItem.name}}" itemprop="url">{{catItem.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags">
                        <h3>Tags</h3>

                        <ul>
                            <li *ngFor="let keyItem of merchantService.merchant.keywords">
                                <a routerLink="/search/{{keyItem.replaceAll(' ','-')}}/1" title="{{keyItem}}" itemprop="url">{{keyItem}}</a>
                            </li>
                            
                        </ul>
                    </div>

                   
                </div>
            </div>
        </div>
        <div class="row">
            <br/>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a itemprop="url" title="Previous page" routerLink="{{blogService.prevLink}}"  class="prev page-numbers">
                        <i class="bx bx-chevron-left"></i>
                    </a>

                    <a itemprop="url" title="Next page" routerLink="{{blogService.nextLink}}" class="next page-numbers">
                        <i class="bx bx-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End log Column One Area -->

<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->