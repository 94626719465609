import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { AlbumDetailsComponent } from './components/pages/album-details/album-details.component';
import { AlbumsComponent } from './components/pages/albums/albums.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogLeftSidebarComponent } from './components/pages/blog-left-sidebar/blog-left-sidebar.component';
import { BlogOneColumnComponent } from './components/pages/blog-one-column/blog-one-column.component';
import { BlogThreeColumnComponent } from './components/pages/blog-three-column/blog-three-column.component';
import { BlogTwoColumnComponent } from './components/pages/blog-two-column/blog-two-column.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DepartmentDetailsComponent } from './components/pages/department-details/department-details.component';
import { DepartmentsComponent } from './components/pages/departments/departments.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { DoctorsComponent } from './components/pages/doctors/doctors.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MyAccountComponent } from './components/pages/my-account/my-account.component';
import { PortfolioDetailsComponent } from './components/pages/portfolio-details/portfolio-details.component';
import { PortfolioFilterComponent } from './components/pages/portfolio-filter/portfolio-filter.component';
import { PortfolioFourColumnComponent } from './components/pages/portfolio-four-column/portfolio-four-column.component';
import { PortfolioThreeColumnComponent } from './components/pages/portfolio-three-column/portfolio-three-column.component';
import { PortfolioTwoColumnComponent } from './components/pages/portfolio-two-column/portfolio-two-column.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesStyleOneComponent } from './components/pages/services-style-one/services-style-one.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import {AuthGuardService} from './core/services/authguard/auth-guard.service';

const routes: Routes = [{
    path: '',
    canActivateChild: [AuthGuardService],
    children:[
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'doctors/:index', component: DoctorsComponent},
    {path: 'doctors/:name/:index', component: DoctorDetailsComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'services/:index', component: ServicesComponent},
    {path: 'services/:name/:index', component: ServicesDetailsComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'departments', component: DepartmentsComponent},
    {path: 'department/:name/:id', component: DepartmentDetailsComponent},
    {path: '404', component: ErrorComponent},
    {path: 'blog/:index', component: BlogOneColumnComponent},
    {path: 'blog/:name/:index', component: BlogDetailsComponent},
    { path: 'albums/:name/:id', component: AlbumDetailsComponent },
    { path: 'albums/:index', component: AlbumsComponent },
    {path: 'contact', component: ContactComponent},

    ]
},
{path: '**', component: ErrorComponent} 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }