<!-- Footer -->
<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
         

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Our Departments</h3>

                    <ul>
                        <li *ngFor="let prodCat of categoryService.productCategories">
                            <a routerLink="/department/{{prodCat.name}}/{{prodCat._id}}" itemprop="url" title="{{prodCat.name}}">{{prodCat.name}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Popular links</h3>

                    <ul>
                        <li><a routerLink="/about" title="About us" itemprop="url">About us</a></li>
                        <li><a routerLink="/appointment" title="Book Appointment" itemprop="url">Appointment</a></li>
                        <li><a routerLink="/contact" title="Contact us" itemprop="url">Contact us</a></li>
                        <li><a routerLink="/testimonials" title="Testimonials" itemprop="url">Testimonials</a></li>
                        <li><a routerLink="/doctors/1" title="Our Doctors" itemprop="url">Our Doctors</a></li>
                        <li><a routerLink="/blog/1" title="Our Blog" itemprop="url">Our Blog</a></li>
                        <li><a routerLink="/sitemap.xml" title="Sitemap" itemprop="url">Sitemap</a></li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Working Hours</h3>
                    <ul class="open-day">
                        <li>
                            Sunday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.sunday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Monday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.monday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Tuesday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.tuesday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Wednesday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.wednesday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Thursday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.thursday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Friday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.friday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                        <li>
                            Saturday
                            <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.saturday}}</span>
                            <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact us</h3>
                    <p class="address"><span>Address:-</span> {{merchantService.merchant.streetAddress}} {{merchantService.merchant.locality}} {{merchantService.merchant.city}} {{merchantService.merchant.country}}</p>
                    <ul>
                        <li>
                            <span>Call:- </span>
                            <a title="Call us" href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}">{{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                        </li>
                        <li>
                            <span>Email:- </span>
                            <a title="Send a Email" href="mailto:{{merchantService.merchant.email}}"> {{merchantService.merchant.email}}</a>
                        </li>
                    </ul>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer -->

<!-- Copyright -->
<div class="copy-right-area">
    <div class="container">
        <p> @{{this.footerYear}} Powered By <strong>{{merchantService.merchant.name}}</strong>.</p>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->