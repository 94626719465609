<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-details-content">
                    <div class="blog-details-img">
                        <img itemprop="image" *ngIf="!blogService.Article.imageModel" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/blog-details/blog-details-img.jpg" alt="{{blogService.Article.title}}">
                        <img itemprop="image" *ngIf="blogService.Article.imageModel" src="{{blogService.Article.imageModel.large}}" alt="{{blogService.Article.title}}">
                    </div>

                    <div class="blog-top-content">
                        <div class="news-content">
                            <ul class="admin">
                                <li> 
                                    <a routerLink="/">
                                        <i class="bx bx-user-circle"></i>
                                        Admin
                                    </a>
                                </li>

                                

                                <li class="float">
                                    <i class="bx bx-calendar-alt"></i>
                                    {{blogService.Article.createdOn | dateFormat}}
                                </li>
                            </ul>
                            
                            <h3 itemprop="name">{{blogService.Article.title}}</h3>

                            <p itemprop="description" style="text-align: justify;" [innerHTML]="blogService.Article.description | keepHtml">Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>

                     
                    </div>

                    <div class="social">
                        <span>Share this post:</span>

                        <ul>
                            <li>
                                <a href="https://www.facebook.com/sharer/sharer.php?u={{this.routerUrl}}" target="_blank">
                                    <i class="bx bxl-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/shareArticle?url={{this.routerUrl}}" target="_blank">
                                    <i class="bx bxl-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/intent/tweet?text=Check+out+&url={{this.routerUrl}}" target="_blank">
                                    <i class="bx bxl-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                   

                    <div class="leave-reply">
                        <h3>Leave a reply</h3>
                        <p>Your email address will not be published. Required fields are marked*</p>

                        <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>First Name*</label>
                                        <input formControlName="firstName" type="text" name="name" id="name" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input formControlName="lastName" type="text" name="msg_subject" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email Address*</label>
                                        <input formControlName="email" type="email" name="email" id="email" class="form-control">
                                    </div>
                                </div>
    
                                
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Phone*</label>
                                        <input formControlName="phone" type="text" name="phone_number" id="phone_number" required class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Message*</label>
                                        <textarea formControlName="messageText" name="message" class="form-control" id="message" cols="30" rows="10"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn btn-two">
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li *ngFor="let catItem of categoryService.productCategories">
                                <a routerLink="/department/{{catItem.name.replaceAll(' ', '-')}}/{{catItem._id}}" title="{{catItem.name}}" >{{catItem.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags">
                        <h3>Tags</h3>

                        <ul>
                            <li *ngFor="let keyItem of merchantService.merchant.keywords">
                                <a routerLink="/search/{{keyItem.replaceAll(' ','-')}}/1" title="{{keyItem}}" itemprop="url">{{keyItem}}</a>
                            </li>
                            
                        </ul>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->

<!-- End Doctor Details -->
<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->